import React, { useContext, useEffect } from "react"
import "./FAQ.css"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FAQdata from "./FAQdata"
import PageTitle from "../shared/elements/PageTitle"
import { Helmet } from "react-helmet"

import { AppContext } from "../shared/context/AppContext"

function FAQ() {
  const { language, setActiveHeader } = useContext(AppContext)

  useEffect(() => {
    setActiveHeader("faq")
  }, [])

  const faqPlaying = FAQdata.filter((faq) => {
    return faq.type === "playing"
  })

  const faqPaying = FAQdata.filter((faq) => {
    return faq.type === "buying"
  })

  const faqTechnical = FAQdata.filter((faq) => {
    return faq.type === "technical"
  })

  const faqOther = FAQdata.filter((faq) => {
    return faq.type === "other"
  })

  var getClosest = function (elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1
        }
    }
    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem
    }
    return null
  }

  const toggleAnswer = (e) => {
    const faqquestion = getClosest(e.target, ".question")
    let questionDivVisible = faqquestion.nextSibling.style.display
    faqquestion.nextSibling.style.display =
      questionDivVisible !== "block" ? "block" : "none"
    faqquestion.firstChild.style.transform =
      questionDivVisible !== "block" ? "rotate(180deg)" : "rotate(0deg)"
  }

  const FAQPlayingComponentsNL = faqPlaying.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.question}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answer}
        </div>
      </div>
    )
  })

  const FAQPlayingComponentsEN = faqPlaying.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.questionEN}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answerEN}
        </div>
      </div>
    )
  })

  const FAQPayingComponentsNL = faqPaying.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.question}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answer}
        </div>
      </div>
    )
  })

  const FAQPayingComponentsEN = faqPaying.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.questionEN}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answerEN}
        </div>
      </div>
    )
  })

  const FAQTechnicalComponentsNL = faqTechnical.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.question}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answer}
        </div>
      </div>
    )
  })

  const FAQTechnicalComponentsEN = faqTechnical.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.questionEN}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answerEN}
        </div>
      </div>
    )
  })

  const FAQOtherComponentsNL = faqOther.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.question}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answer}
        </div>
      </div>
    )
  })

  const FAQOtherComponentsEN = faqOther.map((faq) => {
    return (
      <div className="faqwrapper" key={faq.id} id={`faq${faq.id}`}>
        <div className="question" onClick={(e) => toggleAnswer(e)}>
          <div className="FAQicon" id={`faqicon${faq.id}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {faq.questionEN}
        </div>
        <div className="answer" id={`faqanswer${faq.id}`}>
          {faq.answerEN}
        </div>
      </div>
    )
  })

  return (
    <div className="FAQ">
      <Helmet>
        <title>Veel gestelde vragen | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        <section className="zakelijk-section matrix-bg background-fixed-top">
          <section className="zakelijk-content">
            <h2 className="zakelijk-h2">
              {language === "NL"
                ? "Het spelen van de escape room"
                : "Playing the escape room"}
            </h2>
            {language === "NL"
              ? FAQPlayingComponentsNL
              : FAQPlayingComponentsEN}
          </section>
        </section>
        <section className="zakelijk-section">
          <section className="zakelijk-content">
            <h2 className="zakelijk-h2">
              {language === "NL"
                ? "Aankoop van een toegangscode"
                : "Buying a code"}
            </h2>
            {language === "NL" ? FAQPayingComponentsNL : FAQPayingComponentsEN}
          </section>
        </section>
        <section className="zakelijk-section matrix-bg background-fixed-top">
          <section className="zakelijk-content">
            <h2 className="zakelijk-h2">
              {language === "NL" ? "Technische vragen" : "Technical questions"}
            </h2>
            {language === "NL"
              ? FAQTechnicalComponentsNL
              : FAQTechnicalComponentsEN}
          </section>
        </section>
        <section className="zakelijk-section">
          <section className="zakelijk-content">
            <h2 className="zakelijk-h2">
              {language === "NL" ? "Overige vragen" : "Other questions"}
            </h2>
            {language === "NL" ? FAQOtherComponentsNL : FAQOtherComponentsEN}
          </section>
        </section>
        <section className="zakelijk-section matrix-bg background-fixed-top">
          <section className="zakelijk-content">
            <div id="question-not-div">
              {language === "NL"
                ? "Staat je vraag hier niet tussen? Stuur even een mailtje naar helpdesk@escapemystery.nl, we helpen je graag verder!"
                : "Can't find your question? Send us an e-mail at helpdesk@escapemystery.nl."}
            </div>
          </section>
        </section>
      </div>
    </div>
  )
}

export default FAQ

import React, { Fragment, useContext, useEffect } from "react"
import "./Header.css"
import dots from "./img/dots.png"
import { Link } from "react-router-dom"

import { AppContext } from "../shared/context/AppContext"

function Header() {
  const { language, setLanguage, activeHeader, dotcom } = useContext(AppContext)

  function handleClickThreeDots() {
    var x = document.getElementById("Navigation")
    if (x.className === "Header") {
      x.className += " responsive"
    } else {
      x.className = "Header"
    }
  }

  useEffect(() => {
    if (!dotcom) {
      if (language === "NL") {
        document.getElementById("lan-NL").classList.add("active")
        document.getElementById("lan-EN").classList.remove("active")
      } else {
        document.getElementById("lan-EN").classList.add("active")
        document.getElementById("lan-NL").classList.remove("active")
      }
    }
  }, [language])

  useEffect(() => {
    try {
      const navButtons = document.getElementsByClassName("navButton")
      for (let i = 0; i < navButtons.length; i++) {
        navButtons[i].classList.remove("active")
      }
    } catch {}
    try {
      document.getElementById(`nav-${activeHeader}`).classList.add("active")
    } catch {}
  }, [activeHeader])

  const handleToNL = () => {
    setLanguage("NL")
    window.localStorage.setItem("lang", "NL")
  }

  const handleToEN = () => {
    setLanguage("EN")
    window.localStorage.setItem("lang", "EN")
  }

  function handleClick() {
    handleClickThreeDots()
  }
  return (
    <Fragment>
      <div className="Header" id="Navigation">
        <Link to="/" onClick={handleClick}>
          <h3 id="nav-home" className="navButton">
            Home
          </h3>
        </Link>
        <Link to="/escaperooms" onClick={handleClick}>
          <h3 id="nav-escaperooms" className="navButton">
            Escape rooms
          </h3>
        </Link>
        <Link to="/online_escape_competition" onClick={handleClick}>
          <h3 id="nav-online_escape_competition" className="navButton">
            {language === "NL" ? "Escape Competitie" : "Escape Competition"}
          </h3>
        </Link>
        <Link to="/custom_escape_room" onClick={handleClick}>
          <h3 id="nav-zakelijk" className="navButton">
            {language === "NL" ? "Escape room op maat" : "Custom escape room"}
          </h3>
        </Link>
        <Link to="/faq" onClick={handleClick}>
          <h3 id="nav-faq" className="navButton">
            FAQ
          </h3>
        </Link>

        {!dotcom && (
          <div id="language-toggle" className="navButton">
            <span id="lan-NL" className="active" onClick={handleToNL}>
              NL
            </span>{" "}
            /{" "}
            <span id="lan-EN" onClick={handleToEN}>
              EN
            </span>
          </div>
        )}
        <div className="icon" onClick={handleClickThreeDots}>
          <img className="dotsImg" alt="Dropdown" src={dots} />
        </div>
      </div>
    </Fragment>
  )
}

export default Header

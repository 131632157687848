import React, { Fragment, useContext } from "react"
import "./PayPages.css"
import PayPageTemplate from "./PayPageTemplate"
import { Helmet } from "react-helmet"
import { AppContext } from "../shared/context/AppContext"

function PayKaapseER() {
  const ERid = "kaapseER"
  const { language } = useContext(AppContext)
  return (
    <Fragment>
      <Helmet>
        <title>
          {language === "NL"
            ? "Afrekenen De Kaapse Kode | Escape Mystery"
            : "Checkout De Kaapse Kode | Escape Mystery"}
        </title>
      </Helmet>
      <PayPageTemplate ERid={ERid} />
    </Fragment>
  )
}

export default PayKaapseER

import React, { useState, useContext } from "react"
import "./PayPages.css"

import { faShoppingCart, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import IconBanner from "../shared/elements/IconBanner"

import { AppContext } from "../shared/context/AppContext"

function PayPageTemplate({ ERid }) {
  const { language } = useContext(AppContext)

  const [email1, setEmail1] = useState("")
  const [email2, setEmail2] = useState("")
  const [naam, setNaam] = useState("")
  const [EMAlert, setEMAlert] = useState(false)
  const [algVW, setAlgVW] = useState(false)
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const onChangeEmail1 = (e) => {
    setEmail1(e.target.value)
  }

  const onChangeEmail2 = (e) => {
    setEmail2(e.target.value)
  }

  const onChangeNaam = (e) => {
    setNaam(e.target.value)
  }

  const onChangeEMAlert = (e) => {
    setEMAlert(e.target.checked)
  }

  const onChangealgVW = (e) => {
    setAlgVW(e.target.checked)
  }

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const handleClickAfrekenen = (e) => {
    e.preventDefault()
    setError("")
    const emailsEqual = email1 === email2
    const email1Valid = validateEmail(email1)

    // Check if data is valid
    if (email1.length === 0) {
      language === "NL"
        ? setError("Voer een e-mailadres in")
        : setError("Enter an e-mail address")
      return
    } else if (!emailsEqual) {
      language === "NL"
        ? setError("E-mailadressen zijn niet gelijk")
        : setError("E-mail addresses are not equal")
      return
    } else if (!email1Valid) {
      language === "NL"
        ? setError("Geen bestaand e-mailadres")
        : setError("Not a valid e-mail address")
      return
    } else if (!algVW) {
      language === "NL"
        ? setError("Je moet akkoord gaan met de algemene voorwaarden")
        : setError("You have to accept the terms and conditions")
      return
    }

    // Get payment URL from backend and create new order
    const getData = async () => {
      console.log(process.env.REACT_APP_BACKENDURL)
      setIsLoading(true)
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL + "/payments/orders/createorderOEC",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email1,
              escaperoom: "OEC",
              naam: naam,
              EMAlert: EMAlert,
            }),
          }
        )
        const responseData = await response.json()
        if (!response.ok) {
          language === "NL"
            ? setError(
                "Er is iets fout gegaan op onze servers. Probeer het nog eens."
              )
            : setError("Something went wrong on our servers. Please try again.")
        }
        if (responseData.success) {
          // Redirect to payment URL
          setIsLoading(false)
          window.location.href = responseData.redirectUrl
        } else {
          language === "NL"
            ? setError(
                "Er is iets fout gegaan op onze servers. Probeer het nog eens."
              )
            : setError("Something went wrong on our servers. Please try again.")
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        language === "NL"
          ? setError(
              "Er is iets fout gegaan op onze servers. Probeer het nog eens."
            )
          : setError("Something went wrong on our servers. Please try again.")
      }
    }
    getData()
  }

  return (
    <div className="PayPageWrapper">
      <div className="PageContentWrapper">
        <section className="zakelijk-section cover-bg sand-bg">
          <section className="zakelijk-content">
            <h2 className="zakelijk-h2">
              {language === "NL"
                ? "Online Escape Competitie"
                : "Online Escape Competition"}
            </h2>
            <h3 className="subtitle-zakelijk">
              {language === "NL"
                ? "16 januari tot 26 februari 2022"
                : "January 16 to February 26, 2022"}
            </h3>
            <br /> <br />
            <p>
              {language === "NL"
                ? "Met een team van 2-4 spelers speel je drie keer een online escape room. Je neemt het op tegen alle andere teams die meespelen. Elke escape room zal zo’n 1 a 2 uur duren en je hebt 2 weken de tijd om hem te voltooien. Per team kost het 70 euro, maar als je je voor 12 december inschrijft slechts 50 euro! Hiervoor krijg je drie gloednieuwe escape rooms en een spannende competitie! Lukt het jullie een van de klassementen te winnen?!"
                : "With a team of 2-4 players you play three online escape rooms. You compete against all other teams. Each escape room will take about 1 to 2 hours and you have 2 weeks to complete it. It costs 70 euros per team, but if you register before December 12 only 50 euros! For this you get three brand new escape rooms and an exciting competition! Can you win one of the rankings?!"}
            </p>
          </section>
        </section>
        <div id="jouwBestelling" className="PayPageBlock">
          <h3 className="InfoPageTitle">
            {language === "NL"
              ? "Gegevens van jouw bestelling"
              : "Details of your order"}
          </h3>
          <br />
          {/* <p>
            <div className="bestellingitem">
              <b>Escape room:</b>
            </div>{" "}
            {language === "NL" ? title : titleEN}
          </p> */}
          <p>
            <div className="bestellingitem">
              <b>Product:</b>
            </div>{" "}
            {language === "NL"
              ? "Online Escape Competitie (1 team)"
              : "Online Escape Competitie (1 team)"}
          </p>
          <p>
            <span className="bestellingitem">
              <b>{language === "NL" ? "Prijs" : "Price"}:</b>
            </span>{" "}
            € 50,00
          </p>
        </div>

        <div id="jouwGegevens" className="PayPageBlock">
          <h3 className="InfoPageTitle">
            {language === "NL" ? "Jouw gegevens" : "Your credentials"}
          </h3>
          <br />
          <p>
            <div className="gegevensitem">
              <b>{language === "NL" ? "E-mailadres*" : "E-mail address*"}</b>
            </div>{" "}
            <input
              type="email"
              id="email1"
              className="email_input"
              value={email1}
              onChange={onChangeEmail1}></input>
          </p>
          <p>
            <div className="gegevensitem">
              <b>{language === "NL" ? "E-mail nogmaals" : "E-mail again"}</b>
            </div>{" "}
            <input
              type="email"
              id="email2"
              className="email_input"
              value={email2}
              onChange={onChangeEmail2}></input>
          </p>
          <p>
            <div className="gegevensitem">
              <b>
                {language === "NL" ? "Naam (optioneel)" : "Name (optional)"}
              </b>
            </div>{" "}
            <input
              type="text"
              id="naam_input"
              value={naam}
              onChange={onChangeNaam}></input>
          </p>
          <p>
            <label htmlFor="EMalert" className="container">
              <div className="gegevensitem_long">
                <b>
                  {language === "NL"
                    ? "Opgeven voor Escape Mystery Alert**"
                    : "Sign up for Escape Mystery Alert**"}
                </b>
              </div>{" "}
              <input
                type="checkbox"
                id="EMalert"
                className="checkbox_input"
                value={EMAlert}
                onChange={onChangeEMAlert}></input>
              <span className="checkmark"></span>
            </label>
          </p>
          <p>
            <label htmlFor="algvw" className="container">
              <div className="gegevensitem_long">
                <b>
                  {language === "NL" ? "Akkoord met de " : "Agree to "}
                  <a href="/voorwaarden" target="_blank" rel="noreferrer">
                    {language === "NL"
                      ? "algemene voorwaarden"
                      : "terms and conditions"}
                  </a>
                </b>
              </div>{" "}
              <input
                type="checkbox"
                id="algvw"
                className="checkbox_input"
                value={algVW}
                onChange={onChangealgVW}
              />
              <span className="checkmark"></span>
            </label>
          </p>
          <p className="small">
            <br />
            {language === "NL"
              ? "* Naar dit e-mailadres worden de toegangscodes voor de escape rooms gestuurd. Zorg dus dat deze correct is."
              : "* The access code for the escape rooms will be sent to this e-mail address. So make sure it is correct."}
          </p>
          <p className="small">
            {language === "NL"
              ? "** Als je je aanmeldt voor de Escape Mystery Alert ontvang je een e-mail als er een nieuwe escape room uitkomt."
              : "** If you sign up for the Escape Mystery Alert you will receive an e-mail when a new escape eroom is released."}
          </p>
          <div className="errorWrapper">{error}</div>
        </div>
        <button
          className="afrekenButton"
          onClick={handleClickAfrekenen}
          disabled={isLoading}>
          <div className="buttonIcon">
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} />
            ) : (
              <FontAwesomeIcon icon={faShoppingCart} />
            )}
          </div>
          {language === "NL" ? "Afrekenen" : "Pay"}
        </button>
      </div>
    </div>
  )
}

export default PayPageTemplate

const Quotesdata = [
  {
    id: 4,
    name: "Meike (Groningen)",
    quote:
      "Tof dat je in 360 graden om je heen kan kijken. Het is net alsof je in een echte escape room staat.",
    quoteEN:
      "Cool that you can look around you in 360 degrees. It's like being in a real escape room.",
  },
  {
    id: 6,
    name: "Fransien (Utrecht)",
    quote: "Leuke uitdagende puzzels in een supervette 3d omgeving.",
    quoteEN: "Nice and challending puzzles in a super cool 3d environment.",
  },
  {
    id: 1,
    name: "Patrick (Londen)",
    quote:
      "Uitdagend, met precies de juiste moeilijkheidsgraad om je licht te frustreren, wat het ontzettend fijn maakt om uiteindelijk uit de escape room te onstnappen!",
    quoteEN:
      "Challenging, with just the right level of difficulty, making it really nice to eventually escape from the escape room!",
  },
  {
    id: 5,
    name: "Anna (Rotterdam)",
    quote:
      "Deze online escape room komt heel dichtbij de spanning en beleving van een fysieke escape room, wij hebben ervan genoten!!",
    quoteEN:
      "This online escape room comes very close to the excitement and experience of a physical escape room, we enjoyed it!!",
  },
  {
    id: 2,
    name: "Flora (Delft)",
    quote:
      "Wauw wat een vette puzzels en locaties! Wij hebben ons een avond super vermaakt met deze digitale escape room. Volgende keer doen we een van de andere Escape Mysteries.",
    quoteEN:
      "Wow, very cool puzzles and locations! We had a great time. Next time we will do one of the other Escape Mysteries.",
  },
  {
    id: 3,
    name: "Leo (Utrecht)",
    quote:
      "Wij hebben ons de hele avond met Het Mysterie van Delft vermaakt! Fijn om ook iets over de geschiedenis van Delft te leren tussendoor.",
    quoteEN:
      "We enjoyed ourselves all evening with Het Mysterie van Delft! Nice to also learn something about the history of Delft.",
  },
]

export default Quotesdata

import React, { useContext, useEffect, useState } from "react"
import EscapeRoomTile from "./components/EscapeRoomTile"
import escapeDataImport from "./components/EscapeRoomData"
import "./EscapeRooms.css"
import { Helmet } from "react-helmet"
import { AppContext } from "../shared/context/AppContext"

function EscapeRooms() {
  const { setActiveHeader, dotcom, language } = useContext(AppContext)
  const [escapeData, setEscapeData] = useState([])

  useEffect(() => {
    setActiveHeader("escaperooms")
  }, [])

  useEffect(() => {
    setEscapeData(escapeDataImport)
    if (dotcom || language === "EN") {
      let escapeData2 = escapeDataImport.filter(
        (escaperoom) => escaperoom.english === true
      )
      setEscapeData(escapeData2)
    } else {
      setEscapeData(escapeDataImport)
    }
  }, [setEscapeData, escapeDataImport, dotcom, language])

  const escapeRoomTiles = escapeData.map((ER) => {
    return (
      <EscapeRoomTile
        title={ER.title}
        titleEN={ER.titleEN}
        difficulty={ER.difficulty}
        estTime={ER.estTime}
        playURL={ER.URL}
        price={ER.price}
        text={ER.text}
        textEN={ER.textEN}
        id={ER.id}
        key={ER.id}
        linkto={ER.linkto}
        players={ER.players}
        live={ER.live}
      />
    )
  })

  return (
    <div className="EscapeRooms">
      <Helmet>
        <title>Overzicht escape rooms | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper" id="escaperooms-content-wrapper">
        {/* <PageTitle title="Escape Rooms" /> */}
        {/* <div className="PageSubTitleText" id="EscapeRoomsSubTitle">
          Op deze pagina zie je een overzicht van alle online escape rooms van
          Escape Mystery. Alle escape rooms zijn op je latop/tablet te spelen
          met een team 2-5 spelers.
        </div> */}
        {escapeRoomTiles}
      </div>
    </div>
  )
}

export default EscapeRooms

import React from "react"
import "./WallOfFame.css"
import PageTitle from "../shared/elements/PageTitle"
import { Helmet } from "react-helmet"

function WallOfFame() {
  return (
    <div className="WallOfFame">
      <Helmet>
        <title>Wall of fame | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        <PageTitle title="Wall of fame" />
        <div className="PageSubTitleText" id="EscapeRoomsSubTitle">
          De record-tijden van alle escape rooms
        </div>
      </div>
    </div>
  )
}

export default WallOfFame

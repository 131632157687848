import React, { useContext, useEffect } from "react"
import "./HomepageBody.css"
import Carousel from "./components/Carousel"
import { Link } from "react-router-dom"
import logo from "./img/escape_mystery_logo_1.png"
import { Helmet } from "react-helmet"

import { AppContext } from "../shared/context/AppContext"
// import { BrowserRouter as Router, Link, Route } from "react-router"

function HomepageBody() {
  const { language, setActiveHeader } = useContext(AppContext)

  useEffect(() => {
    setActiveHeader("home")
  }, [])

  return (
    <div className="HomepageBody">
      <Helmet>
        <title>Online virtuele escape rooms | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        <div className="flexWrapper">
          <img
            src={logo}
            className="logo"
            alt="Escape Rooms van Escape Mystery"></img>
          <h2 className="zakelijk-h2 frontpage-margin">
            {language === "NL"
              ? "De spannendste online virtuele escape rooms in 360"
              : "Exciting online virtual escape rooms in 360"}
            &#176;
          </h2>

          <div className="divBorder">
            <Link to="/escaperooms">
              <div className="divBorder1">
                <h2 className="homepageBtn">
                  {language === "NL"
                    ? "Bekijk onze escape rooms"
                    : "Our escape rooms"}
                </h2>
              </div>
            </Link>
          </div>
          <Carousel />

          <div className="divBorder" id="bottom-homepage-button">
            <Link to="/escaperooms/info/miniescaperoom">
              <div className="divBorder1">
                <h2 className="homepageBtn">
                  {language === "NL"
                    ? "Speel de gratis escape room"
                    : "Play the free escape room"}
                </h2>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageBody

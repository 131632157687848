const FAQdata = [
  {
    id: 1,
    type: "playing",
    question: "Hoe lang duurt een escape room? Kunnen we pauze houden?",
    questionEN:
      "How long does it take to play an escape room? Can we take a break?",
    answer:
      "Dat verschilt per escape room, maar staat altijd duidelijk aangegeven bij de algemene informatie. De langere escape rooms bestaan uit meerdere delen, die je niet achter elkaar hoeft te spelen. Er zijn dus pauzemomenten, zodat je even op adem kan komen en later weer verder kunt gaan waar je gebleven was. ",
    answerEN:
      "This is different for each escape room, but is always clearly indicated in the general information. The long escape rooms consist of several parts, which you do not have to play in succession. This means you can take a break between these parts. You can also stop and continue where you left off at a later moment.",
  },
  {
    id: 2,
    type: "playing",
    question: "Kan ik met meer mensen/devices op 1 toegangscode inloggen?",
    questionEN: "Can I log in with more people / devices on 1 access code?",
    answer:
      "Absoluut! Daar is de escape room op gebouwd zelfs. Als je met meerdere mensen inlogt met dezelfde code dan start de tijd van een kamer zodra de eerste persoon naar binnen gaat. De tijd die je in beeld ziet lopen wordt voor iedereen gesynchroniseerd. Ook hints en eventuele straftijd wordt voor alle teamleden gesynchroniseerd.",
    answerEN:
      "Absolutely! If you log in with several people with the same code, the time of a room starts as soon as the first person enters. The time you see on screen is synchronized for everyone. Hints and possible penalty time are also synchronized for all team members.",
  },
  {
    id: 3,
    type: "technical",
    question:
      "Moet ik iets op mijn laptop installeren om de escape rooms te kunnen spelen?",
    questionEN:
      "Do I need to install anything on my laptop to play the escape rooms?",
    answer:
      "Nee, het enige dat je nodig hebt is een apparaat met een internetbrowser en internetverbinding. Alle moderne browsers (zoals Firefox, Chrome, Edge en Safari) werken. Internet Explorer rekenen wij niet tot de moderne browsers.",
    answerEN:
      "No, all you need is a device with an internetbrowser and internet connection. All modern browsers (such as Firefox, Chrome, Edge and Safari) work. We do not consider Internet Explorer to be one of the modern browsers.",
  },
  {
    id: 4,
    type: "playing",
    question:
      "Moet ik met al mijn teamleden in dezelfde ruimte aanwezig zijn om de escape rooms te kunnen spelen?",
    questionEN:
      "Do I have to be in the same room with all my team members to play the escape rooms?",
    answer:
      "Nee! Op afstand samenspelen kan. Iedereen kan met dezelfde toegangscode inloggen op het eigen apparaat. Wel kan het dan handig zijn om bijvoorbeeld in Google Docs samen aantekeningen te maken en via zoom / teams / skype / een ander videobelprogramma onderling contact te houden tijdens het puzzelen, wel zo gezellig.",
    answerEN:
      "No! You can play together remotely. Everyone can log in with the same code on their own device. It can be useful to make notes together in a for example a Google Docs and to keep in touch with each other via zoom / teams / skype / another video calling program while puzzling.",
  },
  {
    id: 5,
    type: "technical",
    question: "Op welke apparaten kan ik spelen?",
    questionEN: "On which devices can I play the escape rooms?",
    answer:
      "Onze escape rooms werken in principe op alle formaat apparaten (pc / mac, laptop, tablet, smartphone). Wel is het zo dat hoe kleiner het scherm hoe minder makkelijk het zoeken en puzzelen is. Daarnaast kan het zijn dat sommige dingen niet goed werken op de smartphone. We adviseren minimaal 1 computer/laptop in je team om het speelplezier zo groot mogelijk te maken.",
    answerEN:
      "In principle, our escape rooms work on all devices (pc / mac, laptop, tablet, smartphone). Note that on a smaller screen, it may be more difficult to find the clues. In addition, some things may not work properly on the smartphone. We recommend at least 1 computer / laptop in your team to maximize the fun.",
  },
  {
    id: 6,
    type: "other",
    question: "Wat doen jullie met mijn gegevens?",
    questionEN: "What do you do with my data?",
    answer:
      "Om een toegangscode te ontvangen vragen we naar jouw e-mailadres zodat we jou de code veilig toe kunnen sturen. Naast het e-mailadres hebben wij geen gegevens van jou. Het e-mailadres wordt uitsluitend gebruikt om de code toe te sturen en, als je daar toestemming voor hebt gegeven, ook om jou een mail te sturen zodra er een nieuw Escape Mystery online komt. Zie voor uitgebreide informatie ons privacy statement.",
    answerEN:
      "To receive an access code, we ask for your e-mail address so that we can send you the code securely. We have no information about you besides the e-mail address. The e-mail address is only used to send the code and, if you have given permission for this, also to send you an email as soon as a new Escape Mystery comes online. For detailed information, see our privacy statement.",
  },
  {
    id: 7,
    type: "playing",
    question: "Wat mag ik allemaal gebruiken om de puzzels op te lossen?",
    questionEN: "What can I use to solve the puzzles?",
    answer:
      "Alles! Vergeet vooral ook niet dat het internet jouw grootste vriend is :-).",
    answerEN:
      "Everything! Don't forget that the internet is your best friend :-).",
  },
  {
    id: 8,
    type: "playing",
    question:
      "Wat is het verschil tussen de mini-escape room en de betaalde escape rooms?",
    questionEN:
      "What is the difference between the mini escape room and the paid escape rooms?",
    answer:
      "De naam zegt het eigenlijk al; de mini-escape room is een mini variant van de betaalde escape rooms. De verhaallijn, de puzzels, de locatie en de opties om als team te spelen zijn kleiner in de mini-escape room. De mini-escape room is ook niet geschikt om met meerdere mensen vanaf verschillende laptops te spelen, omdat de tijden niet gesynchroniseerd worden.",
    answerEN:
      "The mini escape room is a mini version of the paid escape rooms. The storyline, puzzles, location and options to play as a team are smaller in the mini escape room. The mini escape room is also not suitable for playing with several people from different laptops, because the times are not synchronized.",
  },
  {
    id: 9,
    type: "technical",
    question:
      "Ik zie alleen wat knoppen maar geen kamer als ik in de escape room zit.",
    questionEN:
      "I only see some buttons but no room when I'm in the escape room.",
    answer:
      "Probeer eerst de website opnieuw te laden. Je komt dan automatisch terug waar je gebleven was, dus je hoeft niet bang te zijn dat je van voren af aan hoeft te beginnen. Als dit niet werkt kun je de escape room sluiten en opnieuw openen, ook dan kom je uit waar je gebleven was. Voor sommige combinaties van browsers en adblockers werkt de escape room ook niet goed. Je kunt proberen of het wel werkt als je de adblocker sluit. Er zitten overigens geen reclames in de escape rooms. Als dit alles niet werkt, kun je contact opnemen met helpdesk@escapemystery.nl.",
    answerEN:
      "First, try to reload the website. You will then automatically return where you left off, so you don't have to worry about starting all over again. If this does not work, you can close and reopen the escape room. For some combinations of browsers and ad blockers, the escape room does not work properly either. You can try to disable the adblocker. There are no advertisements in the escape rooms by the way. If all of this does not help, you can contact helpdesk@escapemystery.nl.",
  },
  {
    id: 10,
    type: "buying",
    question: "Hoe kan ik betalen?",
    questionEN: "How does the payment work?",
    answer:
      "Betalingen lopen via Mollie, een gerenommeerde partij die zorgt dat alle betalingen veilig verlopen. Daarbij kun je voor de betaling kiezen uit iDeal, creditcard en Apple Pay.",
    answerEN:
      "Payments go through Mollie, a reputable party that ensures that all payments are made securely. You can choose between iDeal, credit card and Apple Pay for the payment.",
  },
  {
    id: 11,
    type: "buying",
    question: "Hoe lang is de toegangscode geldig die ik gekocht heb?",
    questionEN: "How long is the access code that I bought valid?",
    answer:
      "Toegangscodes blijven een heel jaar geldig. Al onze escape rooms zijn in principe binnen een jaar te maken ;-).",
    answerEN:
      "Access codes remain valid for a whole year. In principle, all our escape rooms can be finished within a year ;-).",
  },
  {
    id: 12,
    type: "buying",
    question:
      "Hoe lang duurt het voor ik kan spelen na het kopen van een toegangscode?",
    questionEN:
      "How long does it take before I can start playing after purchasing an access code?",
    answer:
      "Na het betalen krijg je binnen enkele minuten een e-mail met de toegangscode. Zodra je die email binnen hebt kun je de toegangscode direct gebruiken.",
    answerEN:
      "After paying you will receive an email with the access code within a few minutes. As soon as you have received that email, you can immediately use the access code.",
  },
  {
    id: 13,
    type: "buying",
    question: "Kan ik mijn bestelling nog annuleren?",
    questionEN: "Can I still cancel my order?",
    answer:
      "Nee, zodra je een toegangscode hebt ontvangen kun je je bestelling niet meer annuleren.",
    answerEN:
      "No, once you have received an access code you can no longer cancel your order.",
  },
]

export default FAQdata

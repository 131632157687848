import React, { useContext } from "react"
import "./EscapeRoomTile.css"
import DiffIcon from "./DiffIcon"
import {
  faEuroSign,
  faClock,
  faPlayCircle,
  faShoppingCart,
  faInfoCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useHistory } from "react-router-dom"
import IconBanner from "../../shared/elements/IconBanner"

import { AppContext } from "../../shared/context/AppContext"

function EscapeRoomTile({
  title,
  titleEN,
  difficulty,
  estTime,
  price,
  text,
  textEN,
  id,
  linkto,
  playURL,
  players,
  live,
}) {
  const history = useHistory()
  const { language } = useContext(AppContext)

  const redirectToInfo = () => {
    if (live) {
      history.push(`/escaperooms/info/${linkto}`)
    }
  }

  const handleOnHover = () => {
    document.getElementById(`prevpic${id}`).classList.add("zoomhover")
  }

  const handleOffHover = () => {
    document.getElementById(`prevpic${id}`).classList.remove("zoomhover")
  }

  return (
    <div
      className="EscapeRoomTile"
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOffHover}>
      <div className="innerERtile">
        <div className="ERpicture" id={`prevpic${id}`} onClick={redirectToInfo}>
          {live ? (
            price === 0 ? (
              <h3 className="freeBanner">
                {language === "NL" ? "Gratis" : "Free"}
              </h3>
            ) : (
              <h3 className="priceBanner">
                <span className="red">
                  <FontAwesomeIcon icon={faEuroSign}></FontAwesomeIcon>
                </span>{" "}
                {price}
                /team
              </h3>
            )
          ) : (
            <h3 className="notliveBanner">
              {language === "NL" ? "Komt binnenkort" : "Coming soon"}
            </h3>
          )}
          <div className="ERinfoSmall">
            <IconBanner
              difficulty={difficulty}
              estTime={estTime}
              price={price}
              players={players}
            />
          </div>
        </div>
        <div className="ERdescr" onClick={redirectToInfo}>
          <h2 className="ERtitle">{language === "NL" ? title : titleEN}</h2>
          <p className="ERtext">{language === "NL" ? text : textEN}</p>
          <div className="ERinfo">
            <div className="infoItem" id="difficulty">
              {language === "NL" ? "Moeilijkheid:" : "Difficulty"}
              <DiffIcon diff={difficulty} />
            </div>
            <div className="infoItem" id="estTime">
              <span className="red">
                <FontAwesomeIcon icon={faClock} />{" "}
              </span>{" "}
              {estTime} {language === "NL" ? "minuten" : "minutes"}
            </div>
            <div className="infoItem" id="price">
              <div>
                <span className="red">
                  <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                </span>{" "}
                {players} {language === "NL" ? "spelers" : "players"}
              </div>
            </div>
          </div>
        </div>
        {live ? (
          <div className="tileButtons">
            <Link to={`/escaperooms/info/${linkto}`}>
              <div className="tileButton">
                <div className="buttonIconRooms">
                  <FontAwesomeIcon icon={faInfoCircle}> </FontAwesomeIcon>{" "}
                </div>
                Info
              </div>
            </Link>
            <a href={playURL} target="_blank" rel="noopener noreferrer">
              <div className="tileButton">
                <div className="buttonIconRooms">
                  <FontAwesomeIcon icon={faPlayCircle}> </FontAwesomeIcon>{" "}
                </div>
                {language === "NL" ? "Spelen" : "Play"}
              </div>
            </a>

            {price !== 0 ? (
              <Link to={`/escaperooms/pay/${linkto}`}>
                <div className="tileButton">
                  <div className="buttonIconRooms">
                    <FontAwesomeIcon icon={faShoppingCart}> </FontAwesomeIcon>{" "}
                  </div>
                  {language === "NL" ? "Kopen" : "Buy"}
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="tileButtons">
            <div className="tileButton button_inactive">
              <div className="buttonIconRooms">
                <FontAwesomeIcon icon={faInfoCircle}> </FontAwesomeIcon>{" "}
              </div>
              Info
            </div>

            <div className="tileButton button_inactive">
              <div className="buttonIconRooms">
                <FontAwesomeIcon icon={faPlayCircle}> </FontAwesomeIcon>{" "}
              </div>
              {language === "NL" ? "Spelen" : "Play"}
            </div>

            {price !== 0 ? (
              <div className="tileButton button_inactive">
                <div className="buttonIconRooms">
                  <FontAwesomeIcon icon={faShoppingCart}> </FontAwesomeIcon>{" "}
                </div>
                {language === "NL" ? "Kopen" : "Buy"}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default EscapeRoomTile

import React, { useContext } from "react"
import "./PageNotFound.css"
import PageTitle from "../shared/elements/PageTitle"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"

import { AppContext } from "../shared/context/AppContext"

function PageNotFound() {
  const { language } = useContext(AppContext)
  return (
    <div className="PageNotFound">
      <Helmet>
        <title>Pagina niet gevonden | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        {language === "NL" ? (
          <PageTitle title="Pagina niet gevonden" />
        ) : (
          <PageTitle title="Page not found" />
        )}
        <div className="PageSubTitleText" id="EscapeRoomsSubTitle">
          {language === "NL"
            ? "Je bent lekker aan het speuren op onze website, maar deze pagina bestaat helaas niet."
            : "This page does not exists."}
        </div>
        <div className="divBorder">
          <Link to="/">
            <div className="divBorder1">
              <div className="homepageBtn">
                {language === "NL"
                  ? "Terug naar Escape Mystery"
                  : "Back to Escape Mystery"}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound

const DemoKlassementData = [
  {
    teamname: "De Gele Piranhas",
    totaltime: 6536000,
  },
  {
    teamname: "De Speurders",
    totaltime: 5435040,
  },
  {
    teamname: "Team Sales",
    totaltime: 6513000,
  },
  {
    teamname: "Team Sales 2",
    totaltime: 6873000,
  },
  {
    teamname: "Johnny and the boys",
    totaltime: 5876000,
  },
  {
    teamname: "De Detectives",
    totaltime: 4535040,
  },
  {
    teamname: "Escapers",
    totaltime: 14435040,
  },
  {
    teamname: "Infinito",
    totaltime: 4735040,
  },
  {
    teamname: "Jeroen",
    totaltime: 6354040,
  },
  {
    teamname: "Wij zijn de snelste",
    totaltime: 6954040,
  },
  {
    teamname: "Record rabbits",
    totaltime: 6352040,
  },
  {
    teamname: "Kampioenen",
    totaltime: 7867040,
  },
  {
    teamname: "Team Arthur",
    totaltime: 7122040,
  },
  {
    teamname: "Team Martijn",
    totaltime: 7022040,
  },
  {
    teamname: "V3 HB11001",
    totaltime: 7692040,
  },
  {
    teamname: "Puzzelaars",
    totaltime: 8653040,
  },
  {
    teamname: "Escape koningen",
    totaltime: 8833040,
  },
  {
    teamname: "V3 HB13231",
    totaltime: 5647300,
  },
  {
    teamname: "V3 HB13111",
    totaltime: 9873000,
  },
  {
    teamname: "Workaholics",
    totaltime: 10762000,
  },
  {
    teamname: "Innovators",
    totaltime: 8765000,
  },
  {
    teamname: "De bazen",
    totaltime: 9187040,
  },
  {
    teamname: "Verdieping 7",
    totaltime: 8736040,
  },
  {
    teamname: "Verdieping 9",
    totaltime: 7635040,
  },
  {
    teamname: "PitchPerfect",
    totaltime: 7815040,
  },
  {
    teamname: "Los inteligentes",
    totaltime: 6746000,
  },
  {
    teamname: "het teampje",
    totaltime: 6758000,
  },
  {
    teamname: "TIVO",
    totaltime: 5678040,
  },
  {
    teamname: "tim en angela",
    totaltime: 8675040,
  },
  {
    teamname: "Verrekijkers",
    totaltime: 6746040,
  },
  {
    teamname: "Keizers",
    totaltime: 8672040,
  },
]

export default DemoKlassementData

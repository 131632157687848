import React from "react"
import "./PageTitle.css"

function PageTitle({ title }) {
  return (
    <div className="PageTitle">
      <div className="middleBorder">
        <h1 className="innerBorder outlined">{title}</h1>
      </div>
    </div>
  )
}

export default PageTitle

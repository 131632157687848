import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { AppContext } from "../shared/context/AppContext"

// Import CSS
import "./Footer.css"

function Footer() {
  const { language } = useContext(AppContext)
  return (
    <div className="Footer">
      <div className="footer-column">
        <p className="footer-column-title">
          {language === "NL" ? "Volg ons" : "Follow us"}
        </p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/escapemysteryNL">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faFacebook}></FontAwesomeIcon>
                Facebook
              </div>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/escapemysterynl/">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faInstagram}></FontAwesomeIcon>
                Instagram
              </div>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/escape-mystery">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faLinkedin}></FontAwesomeIcon>
                LinkedIn
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <p className="footer-column-title">
          {language === "NL" ? "Op deze website" : "This website"}
        </p>
        <ul>
          <li>
            <Link to="/">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                Home
              </div>
            </Link>
          </li>
          <li>
            <Link to="/escaperooms">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                {language === "NL" ? "Alle escape rooms" : "All escape rooms"}
              </div>
            </Link>
          </li>
          <li>
            <Link to="/online_escape_competition">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                {language === "NL" ? "Escape Competitie" : "Escape Competition"}
              </div>
            </Link>
          </li>
          <li>
            <Link to="/escaperooms/info/delftescaperoom">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                Het Mysterie van Delft
              </div>
            </Link>
          </li>
          <li>
            <Link to="/escaperooms/info/kaapseescaperoom">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                De Kaapse Kode
              </div>
            </Link>
          </li>
          <li>
            <Link to="/escaperooms/info/miniescaperoom">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                De Werfkelder
              </div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <p className="footer-column-title">
          {language === "NL" ? "Bedrijf" : "Company"}
        </p>
        <ul>
          <li>
            <Link to="/custom_escape_room">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                {language === "NL"
                  ? "Escape room op maat"
                  : "Custom escape room"}
              </div>
            </Link>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:info@escapemystery.nl">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                Contact
              </div>
            </a>
          </li>
          <li>
            <Link to="/voorwaarden">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                {language === "NL"
                  ? "Algemene voorwaarden"
                  : "Terms and Conditions"}
              </div>
            </Link>
          </li>
          <li>
            <Link to="/privacy">
              <div className="footer-link">
                <FontAwesomeIcon
                  className="fa-before-replacer"
                  icon={faChevronRight}></FontAwesomeIcon>
                Privacy statement
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer

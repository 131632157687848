import React, { useContext, useEffect } from "react"
import "./Competition.css"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import {
  faCalendarAlt,
  faEuroSign,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PuzzlingImg from "./img/puzzling.jpg"

import { AppContext } from "../shared/context/AppContext"

function Competition() {
  const { language, setActiveHeader } = useContext(AppContext)

  useEffect(() => {
    setActiveHeader("online_escape_competition")
  }, [])

  const mouseEnter = () => {
    const el = document.getElementById("example-div-cover")
    const el_2 = document.getElementById("example-div-title")
    el.style.zIndex = "-1"
    el_2.style.zIndex = "-1"
  }

  const mouseLeave = () => {
    const el = document.getElementById("example-div-cover")
    const el_2 = document.getElementById("example-div-title")
    el.style.zIndex = "2"
    el_2.style.zIndex = "3"
  }

  return (
    <div className="Competition">
      <Helmet>
        <title>
          {language === "NL"
            ? "Online escape competitie | Escape Mystery"
            : "Online escape competition | Escape Mystery"}
        </title>
      </Helmet>
      <section className="zakelijk-section cover-bg sand-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL"
              ? "Online Escape Competitie"
              : "Online Escape Competition"}
          </h2>
          <h3 className="subtitle-zakelijk">
            {language === "NL"
              ? "Puzzel, versla je vrienden en win!"
              : "Puzzle, beat your friends and win!"}
          </h3>
          <h4>Hoe werkt het?</h4>
          <img src={PuzzlingImg} className="img-right no-show-mobile" alt="" />
          <br /> <br />
          <p>
            {language === "NL"
              ? "Met een team van 2-4 spelers speel je drie keer een online escape room. Je neemt het op tegen alle andere teams die meespelen. Elke escape room zal zo’n 1 a 2 uur duren en je hebt 2 weken de tijd om hem te voltooien. Per team kost het 70 euro, maar als je je voor 12 december inschrijft slechts 50 euro! Hiervoor krijg je drie gloednieuwe escape rooms en een spannende competitie! Lukt het jullie een van de klassementen te winnen?!"
              : "With a team of 2-4 players you play three online escape rooms. You compete against all other teams. Each escape room will take about 1 to 2 hours and you have 2 weeks to complete it. It costs 70 euros per team, but if you register before December 12 only 50 euros! For this you get three brand new escape rooms and an exciting competition! Can you win one of the rankings?!"}
          </p>
          <section className="zakelijk-content">
            <div className="divBorder-zakelijk">
              <Link to="/escaperooms/pay/online_escape_competitie">
                <div className="divBorder1">
                  <h2 className="homepageBtn">
                    {language === "NL"
                      ? "Schrijf je team in"
                      : "Register your team"}
                  </h2>
                </div>
              </Link>
            </div>
          </section>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section
          className="zakelijk-content flex centeralign"
          id="summary-competition">
          <div>
            <FontAwesomeIcon icon={faCalendarAlt} className="summary-fa" />
            <p>
              <b>
                {language === "NL"
                  ? "16 januari tot 26 februari 2022"
                  : "January 16 to February 26, 2022"}
              </b>
              <br />
              <br />
              {language === "NL"
                ? "Ronde #1: 16 jan - 29 jan"
                : "Round #1: Jan 16 - Jan 29"}
              <br />
              {language === "NL"
                ? "Ronde #2: 30 jan - 12 feb"
                : "Round #2: Jan 30 - Feb 12"}
              <br />
              {language === "NL"
                ? "Ronde #3: 13 feb - 26 feb"
                : "Round #3: Feb 13 - Feb 26"}
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faEuroSign} className="summary-fa" />
            <p>
              <span className="strikethrough">€70 / team</span>
              <br />
              <b>€50 / team</b>
              <br />
              <br />
              {language === "NL"
                ? "Tot 12 december is het €50 i.p.v. €70 euro per team."
                : "Until December 12, it is €50 instead of €70 per team."}
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faUsers} className="summary-fa" />
            <p>
              {language === "NL"
                ? "Iedereen kan mee doen! Van jong tot oud, Nederlands of Engels en ongeacht enige voorkennis! De enige vereiste is dat je een laptop of computer hebt."
                : "Everyone can join! Young and old, Dutch and in English and regardless of any prior knowledge! The only requirement is that you have a laptop or computer."}
            </p>
          </div>
        </section>
      </section>
      <section className="zakelijk-section cover-bg lock-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "De competitie" : "The competition"}
          </h2>
          <p className="centeralign">
            {language === "NL"
              ? "Daag je vrienden en collega’s uit en bepaal wie de beste escaper is! Er zijn drie soorten klassementen waarin alle teams meestrijden."
              : "Challenge your friends and colleagues and decide who is the best escaper! There are three types of rankings in which all teams compete."}
          </p>
        </section>
        <section className="zakelijk-content flex">
          <div className="klassementen-div">
            <b>
              {language === "NL" ? "Algemeen klassement" : "General ranking"}
            </b>
            <br />
            <br />
            <p>
              {language === "NL"
                ? "In het algemeen klassement worden de totale tijden bijgehouden van alle deelnemers. De winnaars van dit klassement krijgen een verassingscadeau en gratis toegang tot alle escape rooms die Escape Mystery heeft en kan volgend jaar weer gratis meespelen!"
                : "In the general ranking, the total times of all participants are kept. The winners of this ranking will receive a surprise gift and free access to all escape rooms that Escape Mystery has and can play this competition for free next year!"}
            </p>
          </div>

          <div className="klassementen-div">
            <b>{language === "NL" ? "Sprint klassement" : "Sprint ranking"}</b>
            <br />
            <br />
            <p>
              {language === "NL"
                ? "Elke escape room zal beginnen met een sprintpuzzel. Dit is een puzzel die ongeveer 5-15 minuten zal duren. Het draait in dit klassement echt om snelheid!"
                : "Each escape room will start with a sprint puzzle. This is a puzzle that will take about 5-15 minutes. It's really all about speed in this ranking!"}
            </p>
          </div>
          <div className="klassementen-div">
            <b> {language === "NL" ? "Ronde klassement" : "Round ranking"}</b>
            <br />
            <br />
            <p>
              {language === "NL"
                ? "Elke ronde is er een apart klassement voor de escape room van die ronde. Los van hoe je in het algemeen klassement staat is er dus altijd iets te winnen!"
                : "Each round there is a separate leaderboard for the escape room of that round. Regardless of how you rank in the general ranking, there is always something to win!"}
            </p>
          </div>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "De escape rooms" : "The escape rooms"}
          </h2>
          <div className="flex flex-align-center">
            <div
              className="process-step"
              id="example-div"
              onMouseLeave={() => mouseLeave()}>
              <div className="process-step-name" id="example-div-title">
                <p>
                  {language === "NL"
                    ? "Zo ziet een escape room in 360° eruit"
                    : "An online escape room in 360°"}
                </p>
              </div>
              <div
                id="example-div-cover"
                onMouseEnter={() => mouseEnter()}
                onTouchStart={() => mouseEnter()}>
                {language === "NL"
                  ? "Sleep om rond te kijken"
                  : "Drag to look around"}
              </div>
              <iframe
                id="iframe-scale-down"
                title="iframe-example-escape-room"
                src="https://escapemystery.nl/ER_demo/"
                height="400%"
                style={{ width: "400%" }}></iframe>
            </div>
            <p id="competition-escape-room-text">
              {language === "NL"
                ? "Elke escape room is een online escape room in 360 graden zoals je              van Escape Mystery gewend bent. De escape rooms voor deze              competitie zullen zo’n 1 a 2 uur duren en uit 3 delen bestaan. Je              kan tussen de delen in pauze nemen, of zelfs de verschillende              delen over meerdere dagen spelen want de tijd staat stil tussen de              delen in. In elke escape room zal je door goed te puzzelen, naar              clues te zoeken in de virtuele escape room of op het internet en              door goed samen te werken de puzzels kunnen kraken. Als je ergens              niet uitkomt kan je altijd hints ‘kopen’ voor straftijd. Hierdoor              kan je nooit vast komen te zitten."
                : "Each escape room is an online escape room in 360 degrees as all other escape rooms from Escape Mystery. The escape rooms for this competition will last about 1 to 2 hours and consist of 3 parts. You can take a break between the parts, or even play the different parts over several days because time stands still between the parts. In every escape room you will be able to crack the puzzles by puzzling, searching for clues in the virtual escape room or on the internet and by working together. If you're stuck', you can always 'buy' hints for penalty time. This way you can always continue the escape room."}
            </p>
          </div>
          <div className="button-wrapper-zakelijk">
            <div className="divBorder-zakelijk">
              <Link
                to="/dewerfkelder/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="divBorder1">
                  <h2 className="homepageBtn-zakelijk">
                    {language === "NL"
                      ? "Speel onze gratis escape room"
                      : "Play the free escape room"}
                  </h2>
                </div>
              </Link>
            </div>
            <div className="divBorder-zakelijk">
              <Link
                to="/escaperooms/"
                target="_blank"
                rel="noopener noreferrer">
                <div className="divBorder1">
                  <h2 className="homepageBtn-zakelijk">
                    {language === "NL"
                      ? "Bekijk alle escape rooms"
                      : "Check all the escape rooms"}
                  </h2>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </section>

      <section className="zakelijk-section matrix-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "Inschrijven" : "Registration"}
          </h2>
          <p className="centeralign">
            {language === "NL"
              ? "Vorm een team met 2-4 personen, 1 iemand hoeft zich maar in te schrijven per team. Je geeft je e-mailadres op en dat zullen wij gebruiken om het hele team op de hoogte te houden en om jullie t.z.t. de toegangscodes voor de 3 escape rondes te sturen."
              : "Form a team with 2-4 people, only 1 person needs to register per team. You provide your e-mail address and we will use it to keep the entire team informed and to send you the access codes for the 3 escape rounds."}
          </p>
          <div className="divBorder-zakelijk">
            <Link to="/escaperooms/pay/online_escape_competitie">
              <div className="divBorder1">
                <h2 className="homepageBtn">
                  {language === "NL"
                    ? "Schrijf je team in"
                    : "Register your team"}
                </h2>
              </div>
            </Link>
          </div>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "Vragen?" : "Question?"}
          </h2>
          <p className="centeralign">
            {language === "NL" ? "Bekijk onze " : "Check our "}
            <Link to="/faq" className="styled-a">
              FAQ
            </Link>
            {language === "NL"
              ? " voor veelgestelde vragen of stuur gerust een mail naar "
              : " for frequently asked questions or send an email to "}
            <a href="mailto:competition@escapemystery.nl" className="styled-a">
              competitie@escapemystery.nl
            </a>
            {language === "NL"
              ? ", dan beantwoorden we je vraag zo snel mogelijk!"
              : ", and we will answer your question as soon as possible!"}
          </p>
          <br />
        </section>
      </section>
    </div>
  )
}

export default Competition

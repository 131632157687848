import React from "react"
import "../../InfoPages.css"
import "../Screenshots.css"

import screenshot1_thumbnail from "./img/Screenshot1_mini_escaperoom_thumbnail.jpg"
import screenshot1 from "./img/Screenshot1_mini_escaperoom.jpg"

import screenshot2_thumbnail from "./img/Screenshot2_mini_escaperoom_thumbnail.jpg"
import screenshot2 from "./img/Screenshot2_mini_escaperoom.jpg"

import screenshot3_thumbnail from "./img/Screenshot3_mini_escaperoom_thumbnail.jpg"
import screenshot3 from "./img/Screenshot3_mini_escaperoom.jpg"

import { faWindowClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function ScreenshotsMiniER() {
  const showPicture = (e) => {
    let fullPicture = document.getElementById(`${e.target.id}-full`)
    fullPicture.style.display = "block"
  }

  const hidePicture = (e) => {
    const allHide = document.getElementsByClassName(`full`)
    for (let i = 0; i < allHide.length; i++) {
      allHide[i].style.display = "none"
    }
  }

  return (
    <div className="info-screenshots">
      <div className="full" id="screenshot1-full" onClick={hidePicture}>
        <div className="closeX">
          <FontAwesomeIcon icon={faWindowClose} />
        </div>
        <img
          src={screenshot1}
          className="full-image"
          alt="In deze escaperoom kan je in 360 graden om je heen kijken"
        />
      </div>
      <div className="full" id="screenshot2-full" onClick={hidePicture}>
        <div className="closeX">
          <FontAwesomeIcon icon={faWindowClose} />
        </div>
        <img
          src={screenshot2}
          className="full-image"
          alt="In deze escaperoom kan je in 360 graden om je heen kijken"
        />
      </div>
      <div className="full" id="screenshot3-full" onClick={hidePicture}>
        <div className="closeX">
          <FontAwesomeIcon icon={faWindowClose} />
        </div>
        <img
          src={screenshot3}
          className="full-image"
          alt="In deze escaperoom kan je in 360 graden om je heen kijken"
        />
      </div>

      <h3 className="InfoPageTitle">Screenshots</h3>

      <img
        id="screenshot1"
        className="thumbnail"
        src={screenshot1_thumbnail}
        onClick={showPicture}
        alt="In deze escaperoom kan je in 360 graden om je heen kijken"
      />
      <img
        id="screenshot2"
        className="thumbnail"
        src={screenshot2_thumbnail}
        onClick={showPicture}
        alt="In deze escaperoom kan je in 360 graden om je heen kijken"
      />
      <img
        id="screenshot3"
        className="thumbnail"
        src={screenshot3_thumbnail}
        onClick={showPicture}
        alt="In deze escaperoom kan je in 360 graden om je heen kijken"
      />
    </div>
  )
}

export default ScreenshotsMiniER

import React, { useContext, useEffect } from "react"
import "./InfoPages.css"

import EscapeRoomData from "../EscaperoomsPage/components/EscapeRoomData"
import {
  faPlayCircle,
  faShoppingCart,
  faExclamationCircle,
  faLaptop,
  faStreetView,
  faWifi,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import IconBanner from "../shared/elements/IconBanner"
import { AppContext } from "../shared/context/AppContext"
import { Helmet } from "react-helmet"
import ScreenshotsMiniER from "./components/ScreenshotsMiniER/ScreenshotsMiniER"

function MiniEscaperoom() {
  const { language } = useContext(AppContext)

  const { setActiveHeader } = useContext(AppContext)

  useEffect(() => {
    setActiveHeader("escaperooms")
  }, [])

  const ERid = "miniER"
  const {
    difficulty,
    id,
    estTime,
    price,
    text,
    textEN,
    linkto,
    URL,
    players,
    title,
    titleEN,
    subTitle,
    subTitleEN,
  } = EscapeRoomData.find((x) => x.id === ERid)
  return (
    <div className="InfoPageWrapper">
      <Helmet>
        <title>Escaperoom De Werfkelder | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        <div className="pictureBanner" id={id}>
          <h1 className="bannerTitle">{language === "NL" ? title : titleEN}</h1>
          <h2 className="bannerSubTitle">
            {language === "NL" ? subTitle : subTitleEN}
          </h2>
          <IconBanner
            difficulty={difficulty}
            estTime={estTime}
            price={price}
            players={players}
          />
        </div>
        <div className="infoWrapper">
          <div className="infoText">
            <h3 className="InfoPageTitle">
              {language === "NL" ? title : titleEN}
            </h3>
            <div className="PageText" id="EscapeRoomsSubTitle">
              {language === "NL" ? text : textEN}
            </div>{" "}
            <br />
            <h3 className="InfoPageTitle">
              {language === "NL" ? "Hoe werkt het?" : "How does it work?"}
            </h3>
            <table id="delftER-hwh" className="info-table">
              <tbody>
                <tr>
                  <td className="hwh-col1">
                    <span className="fa-table-hwh themakleur">
                      <FontAwesomeIcon icon={faStreetView} />
                    </span>{" "}
                    <br />
                    <h4>360&#176;</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Je kunt op elke locatie 360° om je heen kijken om zo aanwijzingen te vinden en de puzzels op te lossen."
                      : "You can look around in 360° at any location to find clues and solve the puzzles."}
                  </td>
                </tr>
                <tr>
                  <td className="hwh-col1">
                    <span className="fa-table-hwh themakleur">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>{" "}
                    <br />
                    <h4>Hints</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Als je ergens echt niet uitkomt kun je het goede antwoord als hint 'kopen'. Er wordt dan extra tijd bij jouw eindtijd opgeteld."
                      : "If you're stuck on a puzzle, you can 'buy' the correct answer as a hint. Extra time will then be added to your end time."}
                  </td>
                </tr>
                <tr>
                  <td className="hwh-col1">
                    <span className="fa-table-hwh themakleur">
                      <FontAwesomeIcon icon={faLaptop} />
                    </span>{" "}
                    <br />
                    <h4>Devices</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Je speelt De Werfkelder op een laptop, tablet of mobiel. In tegenstelling tot de betaalde escape rooms kun je niet samen spelen en worden tijden niet gesynchroniseerd tussen meerdere devices."
                      : "You can play The Werfkelder on a laptop, tablet or mobile. Unlike the paid escape rooms, you cannot play together and times are not synchronized between multiple devices."}
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 className="InfoPageTitle">Wat heb je nodig?</h3>
            <table id="delftER-hwh" className="info-table">
              <tbody>
                <tr>
                  <td className="hwh-col1">
                    <span className="fa-table-hwh themakleur">
                      <FontAwesomeIcon icon={faWifi} />
                    </span>{" "}
                    <br />
                    <h4>Internet</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Een laptop of tablet met internetverbinding. Je kunt ook op je mobiel spelen, maar het kleine scherm van een mobiel is niet ideaal om alle hints goed te kunnen lezen en het zou kunnen dat sommige dingen niet goed werken op je mobiel."
                      : "A laptop or tablet with internet connection. You can also play on your mobile phone, but the small screen of a mobile is not ideal for reading all the hints and some things may not work properly on your mobile phone."}
                  </td>
                </tr>
                <tr>
                  <td className="hwh-col1">
                    <span className="fa-table-hwh themakleur">
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </span>{" "}
                    <br />
                    <h4>{language === "NL" ? "Notities" : "Notes"}</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Het kan handig zijn om pen en papier of een digitaal kladblok bij de hand te houden om de puzzels op te lossen."
                      : "You may find it helpful to use pen and paper or a digital notepad to solve the puzzles."}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="info-sidebar">
            <div id="buttons-sidebar">
              <a href={URL} target="_blank" rel="noopener noreferrer">
                <div className="infoButton">
                  <div className="buttonIcon">
                    <FontAwesomeIcon icon={faPlayCircle}> </FontAwesomeIcon>{" "}
                  </div>
                  Start
                </div>
              </a>

              {price !== 0 ? (
                <Link to={`/escaperooms/pay/${linkto}`}>
                  <div className="infoButton">
                    <div className="buttonIcon">
                      <FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>
                    </div>
                    Kopen
                  </div>
                </Link>
              ) : (
                ""
              )}
            </div>
            <ScreenshotsMiniER />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniEscaperoom
